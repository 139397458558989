import React from "react";
import HeroSection from "../components/HeroSection";
import ContactBanner from "../components/ContactBanner";
import Services from "../components/Services";



function ServicesPage() {
  return (
    <>
      <HeroSection
        title="services"
        text={`Transform your business and bring your ideas to life on the Salesforce platform
              with our expert development services.`}/>
      <Services/>
      <ContactBanner />
    </>
  );
}

export default ServicesPage;
