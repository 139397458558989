import React from "react";
import "./NotFound.css";
import notFoundImage from "../images/notfound.png";


function NotFound() {
  return (
    <>
      <div className="notfound-wrapper">
        <div className="notfound-image">
            <img src={notFoundImage} alt="Page not found"/>
        </div>
        <div className="notfound-text">
            Sorry, we couldn't find that page
        </div>
        </div>
    </>
  );
}

export default NotFound;
