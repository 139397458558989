import React from "react";
import HeroSection from "../components/HeroSection";
import ServiceCards from "../components/ServiceCards";
import ContactBanner from "../components/ContactBanner";

function HomePage() {
  return (
    <>
      <HeroSection
        title="salesforce development experts"
        text={
          `Enhance your business with our innovative solutions and development services, designed to maximise the potential of the Salesforce platform.`}
      />
      <ServiceCards />
      <ContactBanner />
    </>
  );
}

export default HomePage;
