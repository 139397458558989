import { useState } from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.css';

function ContactForm () {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stateMessage, setStateMessage] = useState(null);

    const serviceId = 'service_ghz4wxk';
    const templateId = 'template_awy7e49';
    const publicKey = '72VYJaJSIvGfRDaRW';

    const sendEmail = (e) => {
      setStateMessage(null);
      e.persist();
      e.preventDefault();
      setIsSubmitting(true);

      // Check form is populated
      if(!e.target.name.value){
        setStateMessage('Error: please provide a name');
        setIsSubmitting(false);
        return;
      }

      if(!e.target.email.value){
        setStateMessage('Error: please provide an email');
        setIsSubmitting(false);
        return;
      }

      if(!e.target.message.value){
        setStateMessage('Error: please provide a message');
        setIsSubmitting(false);
        return;
      }

      emailjs.sendForm(
          serviceId,
          templateId,
          e.target,
          publicKey
        )
        .then(
          (result) => {
            setStateMessage('Message sent!');
            setIsSubmitting(false);
            setTimeout(() => {
              setStateMessage(null);
            }, 5000); // hide message after 5 seconds
          },
          (error) => {
            setStateMessage('Something went wrong, please try again later');
            setIsSubmitting(false);
            setTimeout(() => {
              setStateMessage(null);
            }, 5000); // hide message after 5 seconds
          }
        );
      
      // Clears the form after sending the email
      e.target.reset();
    };



    return (
      <form onSubmit={sendEmail}>
        <label className="contact-form-label">name<span className="fullstop">.</span></label>
        <input className="contact-form-input" type="text" name="name" />
        
        <label className="contact-form-label">email<span className="fullstop">.</span></label>
        <input className="contact-form-input" type="email" name="email" />
        
        <label className="contact-form-label">message<span className="fullstop">.</span></label>
        <textarea className="contact-form-message" name="message" />
        <div className="g-recaptcha" data-sitekey="6LdNEVgpAAAAAAVzz2B0O0cyLq1NUSE_GJf5EVme"></div>

        <input className="contact-form-button" type="submit" value="send" disabled={isSubmitting} />
        {stateMessage && <p>{stateMessage}</p>}
      </form>
      
    );
  };
  export default ContactForm;