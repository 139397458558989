import React from "react";
import HeroSection from "../components/HeroSection";
import Team from "../components/Team";
import ContactBanner from "../components/ContactBanner";
import Clients from "../components/Clients";

function AboutPage() {
  return (
    <>
      <HeroSection
        title="about"
        text={`We’re a team of Salesforce experts with a deep understanding of business process and technology.`}
      />
      <Team/>
      <Clients />
      <ContactBanner />

    </>
  );
}

export default AboutPage;
