import React from "react";
import "./ServiceCards.css";
import Card from "./Card";
import serviceImage1 from "../images/service-icon-app-dev.png";
import serviceImage2 from "../images/service-icon-devops.png";
import serviceImage3 from "../images/service-icon-integration.png";

function ServiceCards() {
  return (
    <>
      <div className="service-cards-container">
        <Card
          title="development"
          image={serviceImage1}
          link='/services#app-dev'/>
        <Card
          title="integration"
          image={serviceImage3}
          link='/services#integration'/>
        <Card
          title="devops"
          image={serviceImage2}
          link='/services#devops'/>
      </div>
    </>
  );
}

export default ServiceCards;
